import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import ovitOntrak from "../Img/ovitOntrak.png";
import { useState } from "react";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        margin: 20,
    },
    root2: {
        maxWidth: 345,
        margin: 20,
        boxShadow: '0px 4px 15px 5px rgba(0,72,255,0.5)'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    // contactBackground: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '50% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right',
    // },
    // contactBackgroundMobil: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '100% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right'
    // }
    media: {
        height: 0,
        paddingTop: '80%', // 16:9
    },
});

const PreferedVendors = () => {
    const classes = useStyles();
    const [cardStyleHover, setCardStyleHover] = useState(classes.root);

    const setHoverStyle = (val) => {
        val ?
            setCardStyleHover(classes.root2)
            :
            setCardStyleHover(classes.root)
    }
    return (
        <Fragment>
            <section id="preferedvendors">
                <br />
                <h2>Prefered Vendors</h2>
                <FadeInSection>
                    <Grid container >
                        <Grid item xs={12} md={3} lg={3}>
                            <Card
                                className={cardStyleHover}
                                onMouseEnter={() => setHoverStyle(true)}
                                onMouseLeave={() => setHoverStyle(false)}
                            >
                                <CardMedia
                                    component="img"
                                    alt="OVIT"
                                    height="120"
                                    image={ovitOntrak}
                                    title="OVIT"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Video Insurance Telematics
          </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Video-based fleet management solutions.
                                        <br />
                                        <a href="/ontrak" target="_blank">More info</a>
                                    </Typography>
                                </CardContent>

                            </Card>
                        </Grid>
                    </Grid>
                </FadeInSection>
            </section>
        </Fragment>
    );
}

export default PreferedVendors;