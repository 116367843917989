import { Button, Card, CardContent, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Fragment, React, useState } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import { gql, useMutation } from '@apollo/client';
import axios from 'axios';
import TransitionModalClaim from "./Helpers/TransitionModalClaim";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15,
        textAlign: 'left'
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const CLAIM_FORM = gql`
  mutation addClaim($input: claim_input) {
    addClaim(input: $input) {
        id,
        accidentLocation,
        fullName,
        phoneNumber,
        email,
        date,
        policyNumber,
        accidentDescription
    }
  }
`;

const Claims = () => {
    const classes = useStyles();
    const [saveClaim] = useMutation(CLAIM_FORM);
    const [file, setFile] = useState('');
    const [idClaim, setIdClaim] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [claim, setClaim] = useState({
        accidentLocation: "",
        fullName: "",
        phoneNumber: "",
        email: "",
        date: "",
        policyNumber: "",
        accidentDescription: ""
    })

    const handleFilesChange = (e) => {
        //let filesList = Array.from(e.target.files).map((file => file))
        setFile(e.target.files);
    }

    const handleChange = (e) => {
        console.log(e.target.name)
        setClaim({
            ...claim,
            [e.target.name]: e.target.value
        })
    }

    const handleReset = () => {
        setClaim({
            accidentLocation: "",
            fullName: "",
            phoneNumber: "",
            email: "",
            date: "",
            policyNumber: "",
            accidentDescription: ""
        })
    }

    const handleSave = async e => {
        saveClaim({ variables: { input: claim } }).then((res) => {
            const formData = new FormData();
            Array.from(file).map(fileSend => (
                formData.append('file', fileSend)
            ))
            formData.append('formId', res.data.addClaim.id);
            formData.append('requestEmail', res.data.addClaim.email);
            formData.append('requestNumber', res.data.addClaim.id);
            try {
                axios.post('http://server.gsiay.com/uploadPhotos/', formData, {
                    //const res = axios.post('http://localhost:8081/uploadPhotos', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })


            } catch (error) {

            }
            handleReset();
            setIdClaim(res.data.addClaim.id)
            setModalOpen(true);
        });
    }

    return (
        <Fragment>
            <section id="claims">
                <h2>Claims</h2>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container justify="center" style={{ backgroundColor: '#f1f1f169', paddingTop: '30px' }}>
                            <Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>
                            <form action="https://formsubmit.co/claims@gsiay.com" method="POST" target="_blank">
                            <Grid item xs={5} >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accident Location  
                            <input type="text" id="al" name="Acciden Location"  required/>
                            </Grid>
                            <br />
                            <Grid item xs={5}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Full Name
                            <input type="text" id="fn" name="Full Name"  required/>
                            </Grid>
                            <br />
                            <Grid item xs={5}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone Number
                            <input type="number" id="pn" name="Phone Number"  required/>
                            </Grid>
                            <br />
                            <Grid item xs={5}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email
                            <input type="email" id="email" name="Email"  required/>
                            </Grid>
                            <br />
                            <Grid item xs={5}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date
                            <input type="date" id="date" name="Date"  required/>
                            </Grid>
                            <br /> 
                            <Grid item xs={5}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Policy Number
                            <input type="text" id="policy" name="Policy#"  required/>
                            </Grid>
                            <br />
                            <Grid item xs={5}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accident Description
                            <input type="text" id="ad" name="Accident Description" maxlength="10000" required/>
                            </Grid>
                           
                            <Grid item xs={7} style={{ padding: '25px'}}>
                                <button variant="contained" color="primary" type="submit" >
                                Send
                                </button>
                            </Grid> 
                            </form>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} style={{ marginTop: "30%" }} color="textSecondary">
                                    <FadeInSection>
                                        {/* <img src={claims} width="100%"></img> */}
                                        <p>
                                            GSIAY is ready to help manage your claim as quickly and efficiently as possible. Report a claim and start the claims process.
                                        </p>
                                        <p>
                                            Notify our Claims Dept. ASAP at <a href="tel:(949)359-5600"><b><font size= "4">(949) 359-5600</font></b></a> or email your claim information to <a href="mailto:claims@gsiay.com"><font size= "4">claims@gsiay.com</font></a> 
                                        </p>
                                        <br /><br /><br /><br />
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <TransitionModalClaim isOpen={modalOpen} setModalOpen={setModalOpen} reqNumber={idClaim}></TransitionModalClaim>
            </section>
        </Fragment>
    );
}

export default Claims;