import { Button, Grid, Typography, TextField} from "@material-ui/core";
import { Fragment, React, useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

    const LossRuns = () => {
    const form = useRef();
    const notify1 = () => toast.success('Your Loss Run entered Successfully, please refresh', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });    
    
    
    const [show,setShow]=useState(true)
    const sendEmail = (e) => {
    e.preventDefault();
   

    emailjs.sendForm('service_5mp8g4v', 'template_7xlurxi', form.current, 'dGixoNAOo1Y9dsTat')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
    return (
        <Fragment>
            <section id="lossruns">
            
            <div className="row">
                    <div className="twelve columns main-col">
                <h2>LOSS RUNS</h2>
                <Grid container>
                    {/*<Grid item xs={12} md={6} lg={6} style={{ padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container justify="center" style={{ backgroundColor: '#f1f1f169', paddingTop: '30px' }}>
                        <Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>  
                            <form class="row" style={{ margin:"25px 85px 75px 100px"}} ref={form} onSubmit={sendEmail} >
                        <p>Requested by:&nbsp;&nbsp;<input type="radio" id="Broker" name="Broker" value="Broker" onClick={()=>setShow(true)}/><label for="Broker">Broker</label>&nbsp;&nbsp;&nbsp;
                        <input type="radio" id="Insured" name="Broker" value="Insured" onClick={()=>setShow(false)}/><label for="Insured">Insured</label></p> 
                            
                        <Grid item xs={12} > 
                        Requestor
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="name" size="small" variant="outlined"  fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control" />
                        </Grid>
                        {show?<div> 
                            <Grid item xs={12} >
                        Agency Name
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="agency" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                            </Grid>

                        <Grid item xs={12} >
                        Producer Code
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="brknum" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>
                        
                        <Grid item xs={12} >
                            Address
                            </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="address" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                            </Grid>
                        
                        <Grid item xs={12} >
                            City
                            </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="city" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>
                        
                        <Grid item xs={12} >
                            State
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="state" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>
                        
                        <Grid item xs={12} >
                           Zip
                            </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="zip" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>
                        
                        <Grid item xs={12} >
                            Phone Number
                            </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="phone" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                            </Grid>
                        
                        <Grid item xs={12} >
                             Email
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="email" name="email" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                            </Grid>

                        <h5 style={{marginTop: "30px" }}>Insured info</h5>
                        </div>:null}
                        <Grid item xs={12} >
                        Insured Full Name
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="insname" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        DBA / Legal Name
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="dba" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/> 
                        </Grid>

                        <Grid item xs={12} >
                        Address
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="insaddress" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        City
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="inscity" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        State
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="insstate" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        Zip
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="inszip" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        Phone Number
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="insphone" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} placeholder="Enter the same phone number given at the time of binding." class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        Email
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="email" name="insmail" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} placeholder="Enter the same phone number given at the time of binding." class="form-control"/>
                        </Grid>

                        <h5 style={{marginTop: "30px" }}>Policy Number and Effective Date</h5>
                        <Grid item xs={12} >
                        Liability
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="Liab" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        Cargo
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="Cargo" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/> 
                        </Grid>

                        <Grid item xs={12} >
                        Physical Damage
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="PD" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                         Other
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="text" name="Other" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>

                        <Grid item xs={12} >
                        If this is not the first request, 
                            please specify when and how it was submitted
                            </Grid>
                            <Grid item xs={12} >
                            <TextField type="text" name="raw" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                            </Grid>

                            <Grid item xs={12} >
                         Remarks
                        </Grid>
                        <Grid item xs={12} >
                        <TextField type="message" rows="6" name="remarks" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white'}} class="form-control"/>
                        </Grid>
                        <Grid item xs={12} >
                        <h5 style={{marginTop: "30px" }} for="lossfiles">Select files:</h5><input style={{marginLeft: "50px", marginTop: "15px"}} type="file" id="lossfiles" name="lossfiles" multiple disabled></input>
                        </Grid>
                        <Grid item xs={12} >
                        <input type="submit" value="Send" class="form-control" style={{marginTop: "30px" }} onClick = {notify1}/>
                        </Grid>
                        </form>
                        <ToastContainer 
                        position="top-center" autoClose={5000} 
                        hideProgressBar={false} 
                        newestOnTop={false} 
                        closeOnClick
                        rtl={false} 
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>
                        </Grid>
                        </Grid>
                    <Grid item xs={12} md={6} lg={6} >*/}
                        
                            
                        <Typography  style={{ marginTop: "10%" }} color="textSecondary">
                                <div style={{ textAlign: 'justify' }}></div>
                                <p style={{ textAlign: 'justify' }}>    
                                At GSIAY, we are commited to quality and efficiency in our services. Please download the following
                            ACORD 611 form, fill it in, sign it and email it to us at <a href="mailto:lossrunrequest@gsiay.com">lossrunrequest@gsiay.com</a>.
                                            
                            </p>
                            <p>
                            <Button variant="contained" color="primary" ><Link style={{ color: 'white' }} to="/files/ACORD.pdf" target="_blank" download> Download ACORD 611 form here</Link> </Button>
                            </p>
                            <p>
                            You may also submit your loss runs by filling in the form and attached any significant documents to get them processed.
                            </p>
                            
                            <p>
                            Once the request is received, it will be reviewed and you will be contacted by one of our
                            costumer service representative, should any questions or issues arise. 
                            </p>
                            <br/><br/><br/><br/><br/><br/>
                                   
                             </Typography>
                           </Grid>
                            </div></div>
                               {/*</Grid>*/}
                
                 
            </section>
        </Fragment>
    );
    }  

export default LossRuns;