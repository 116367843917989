import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { useState } from "react";
import { Fragment, React } from "react";
import contact from "../Img/contact.png";
import config from "../config";
import axios from 'axios';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    gridRowImage: {
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const Contact = () => {
    const classes = useStyles();
    const [contactForm, setContactForm] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        message: ''
    })
    const handleChange = (e) => {
        console.log(e)
        setContactForm({
            ...contactForm,
            [e.target.name]: e.target.value
        })
    }
    const handleReset = () => {
        setContactForm({
            name: '',
            phoneNumber: '',
            email: '',
            message: ''
        })
    }
    const sendContactRequest = () => {
        const formData = new FormData();
        formData.append('requestContact', JSON.stringify(contactForm));
        try {
            axios.post(config.Server + 'api/contact/', formData).then((resp) => {
                handleReset();
                alert("Request sent");
            })
        } catch (error) {

        }
    }
    return (
        <Fragment>
            <section id="contact">
                <h2>Contact</h2>
                <Grid container direction="row-reverse" >
                    <Grid item xs={12} md={4} lg={4} style={{ paddingTop: 50, textAlign: 'left' }}>
                        <div>
                            <h4 style={{ color: 'black' }}>Direct contact</h4>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Underwriting  <a href="mailto:underwriting@gsiay.com">(underwriting@gsiay.com)</a> (706) 395-4300</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Broker appointments <a href="mailto:bap@gsiay.com">(bap@gsiay.com)</a> (706) 395-3800</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Compliance <a href="mailto:compliance@gsiay.com">(compliance@gsiay.com)</a> (619) 568-5900</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Claims <a href="mailto:claims@gsiay.com">(claims@gsiay.com)</a> (949) 359-5600</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>General inquiry <a href="mailto:generalinquiries@gsiay.com">(generalinquiries@gsiay.com)</a></p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Certificates <a href="mailto:certificates@gsiay.com">(certificates@gsiay.com)</a></p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} lg={5} style={{ padding: 60 }}>
                        <Grid container justify="center" style={{ backgroundColor: '#f1f1f169', paddingTop: '30px' }}>
                            <Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>
                            <Grid item xs={3}>
                                Your Name
                            </Grid>
                            <Grid item xs={5} >
                                <TextField name="name"
                                    value={contactForm.name}
                                    onChange={handleChange}
                                    size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Telephone
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="phoneNumber"
                                    value={contactForm.phoneNumber}
                                    onChange={handleChange}
                                    size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                E-mail
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="email"
                                    value={contactForm.email}
                                    onChange={handleChange}
                                    size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Message
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="message"
                                    value={contactForm.message}
                                    onChange={handleChange}
                                    multiline rows={4} fullWidth variant="outlined" style={{ backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={5} style={{ padding: '25px', textAlign: 'end' }}>
                                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Contact  <a href="mailto:contact@gsiay.com">(contact@gsiay.com)</a><br /> <a href="tel:(706) 395-4200">(706) 395-4200</a></p>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: '20px', textAlign: 'end' }}>
                                <Button onClick={handleReset} variant="contained" color="primary">
                                    Reset
                                </Button>
                                <Button onClick={sendContactRequest} variant="contained" color="primary" style={{ marginLeft: '10px' }}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} className={classes.gridRowImage}>

                        <img alt="customer service agent" src={contact} width="100%"></img>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default Contact;