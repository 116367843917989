import { Fragment, React } from "react";
import OVIT from "../Img/OVIT.png";



const Ontrak = () => {
    return (
        <Fragment>
            <img alt="OVIT" src={OVIT} width="50%" height="auto" />
        </Fragment>
    );
}

export default Ontrak;