import { Button, Grid, Typography, TextField} from "@material-ui/core";
import { Fragment, React, useRef, useState } from "react";
import Banner from "../Img/epay2.png";

const Epay = () => {
    return (
        <Fragment>
            <section id="epay">
            
           {/* <div className="row">
                    <div className="twelve columns main-col">*/}
            <Grid item xs={12} style={{ height: '50%', backgroundColor: '#0c2b43'}} >
                <Grid container justify="center">    
                    <Grid lg={6} >
                            <a href="https://gsiay.epaypolicy.com/" target="_blank"> <img src={Banner}/></a>
                    </Grid>
                </Grid>
            </Grid>
                    {/*</div>
            </div>*/}
            </section>
        </Fragment>
    );
} 

export default Epay;