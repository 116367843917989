import {
  AppBar,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Menu,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { forwardRef, Fragment, React, useState } from "react";
import CarouselComponent from "./Helpers/CarouselComponent";
import clsx from "clsx";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CallIcon from "@material-ui/icons/Call";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { Link } from "react-router-dom";
import gsiayLogo from "../Img/gsiay.png";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    color: "#838C95",
    padding: 10,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
    fontSize: 15,
    textAlign: "left",
  },
  coveragesBackground: {
    // backgroundImage: `url(${background}) !important`,
    // backgroundSize: '50% 100%',
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  coveragesBackgroundMobil: {
    // backgroundImage: `url(${background}) !important`,
    // backgroundSize: '100% 100%',
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  logos: {
    // padding:'5px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    width: 150,
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NavBar1 = () => {
  const classes = useStyles();

  const [auth, setAuth] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAccountingDialog, setOpenAccountingDialog] = useState(false);
  const [currentSection, setCurrentSection] = useState("#coverages");
  console.log(currentSection);

  const handleCloseCoverages = () => {
    setOpen(false);
  };

  const cardClick = (e) => {
    setOpenAccountingDialog(true);
  };

  const handleCloseAccountingDialog = () => {
    setOpenAccountingDialog(false);
  };

  const handleNextSectionClick = () => {
    let section = window.location.href;
    section = section.substring(section.indexOf("#"));
    console.log(section);
    switch (section) {
      case "":
      case "#home":
        setCurrentSection("#markets");
        break;
      case "#coverages":
        setCurrentSection("#about");
        break;
      case "#markets":
        setCurrentSection("#getquote");
        break;
      case "#about":
        setCurrentSection("#becomeproducer");
        break;
      case "#getquote":
        setCurrentSection("#contact");
        break;
      case "#becomeproducer":
        setCurrentSection("#claims");
        break;
      case "#contact":
        setCurrentSection("#officeofcompliance");
        break;
      case "#claims":
        setCurrentSection("#careers");
        break;
      case "#officeofcompliance":
        setCurrentSection("#resources");
        break;
      case "#careers":
        setCurrentSection("#home");
        break;
      case "#resources":
        setCurrentSection("#coverages");
        break;
      case "#lossruns":
        setCurrentSection("#lossruns");
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <nav id="nav-wrap" className="navbar navbar-expand-lg fixed-top ">
        <div
          className="container-fluid d-flex justify-content-around navbar-light bg-white"
          id="navbarNav"
        >
          <ul
            id="nav"
            className="navbar-brand w-100 justify-content-between navbar-light bg-white"
          >
            <li className="nav-item">
              <a className="navbar-brand navbar" href="#">
                <img alt="Logo" width="120" height="90" src={gsiayLogo}></img>
              </a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">
                HOME
              </a>
            </li> */}
            <li className="nav-item navbar-light bg-white">
              <a className="smoothscroll" href="#coverages">
                PRODUCT LINES{" "}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="smoothscroll"
                href="#markets"
                onMouseEnter={handleCloseCoverages}
              >
                MARKETS
              </a>
            </li>
            <li className="nav-item">
              <a className="smoothscroll" href="#about">
                ABOUT US
              </a>
            </li>
            <li className="nav-item">
              <a className="link-primary" href="#getquote">
                <u>GET QUOTE</u>
                {/* <Chip
                  label="GET QUOTE"
                  style={{ backgroundColor: "#9e9e9e", color: "#fafafa" }}
                /> */}
              </a>
            </li>
            <li className="nav-item">
              <a className="link-primary" href="#becomeproducer">
                <u>BECOME PRODUCER </u>
                {/* <Chip
                  label="BECOME PRODUCER"
                  style={{ backgroundColor: "#9e9e9e", color: "#fafafa" }}
                /> */}
              </a>
            </li>
            <li className="nav-item">
              <a className="link-primary" href="#contact">
                {/* <u>CONTACT US</u> */}
                {/* <Chip label="CONTACT US" style={{backgroundColor:"#9e9e9e", color:"#fafafa"}} /> */}
                <u> CONTACT US </u>
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#claims">
                CLAIMS
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#officeofcompliance">
                COMPLIANCE
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#careers">
                CAREERS
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#preferedvendors">
                VENDORS
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#resources">
                RESOURCES
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#lossruns">
                LOSS RUNS
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* <nav id="nav-wrap">        
        <ul id="nav" className="navbar">
          <li className="current">
            <a
              onMouseEnter={handleCloseCoverages}
              className="smoothscroll"
              href="#home"
            >
              <img alt="Logo" width="80" height="80" src={gsiayLogo}></img>
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#coverages">
              PRODUCT LINES{" "}
            </a>
          </li>
          <li>
            <a
              className="smoothscroll"
              href="#markets"
              onMouseEnter={handleCloseCoverages}
            >
              MARKETS
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about">
              ABOUT US
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#getquote">
              GET QUOTE
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#becomeproducer">
              BECOME PRODUCER
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact">
              CONTACT US
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#claims">
              CLAIMS
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#officeofcompliance">
              COMPLIANCE
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#careers">
              CAREERS
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#preferedvendors">
              VENDORS
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#resources">
              RESOURCES
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#lossruns">
              LOSS RUNS
            </a>
          </li>
        </ul>
      
      </nav> */}
      <Fragment>
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", marginTop: "80px" }}
        >
          <Grid
            container
            alignItems="center"
            alignContent="center"
            style={{ flexFlow: "column" }}
          >
            <Grid item xs={12} style={{ display: "flex" }}>
              <Card
                className={clsx("menuCardsHover")}
                id="acc"
                onClick={cardClick}
                elevation={0}
                style={{ width: "200px" }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: "#36af20" }}
                    >
                      <AccountBalanceIcon style={{ color: "white" }} />
                    </Avatar>
                  }
                  title="Accounting"
                  subheader="More info"
                />
              </Card>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{ margin: "10px" }}
              />
              <Card
                className={clsx("menuCardsHover")}
                id="cs"
                elevation={0}
                onClick={() => (window.location.href = "#contact")}
                style={{ width: "210px" }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: "#36af20" }}
                    >
                      <CallIcon style={{ color: "white" }} />
                    </Avatar>
                  }
                  title="Customer Service"
                  subheader="More info"
                />
              </Card>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{ margin: "10px" }}
              />
              <Card
                className={clsx("menuCardsHover")}
                id="cert"
                elevation={0}
                style={{ width: "200px" }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: "#36af20" }}
                    >
                      <InsertDriveFileIcon style={{ color: "white" }} />
                    </Avatar>
                  }
                  title={
                    <Button>
                      <Link
                        to={process.env.PUBLIC_URL + "files/Certificates.html"}
                        target="_blank"
                      >
                        Certificates
                      </Link>
                    </Button>
                  }
                ></CardHeader>
              </Card>
              <Card
                className={clsx("menuCardsHover")}
                id="cert"
                elevation={0}
                style={{ width: "220px" }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      style={{ backgroundColor: "#36af20" }}
                    >
                      <InsertDriveFileIcon style={{ color: "white" }} />
                    </Avatar>
                  }
                  title={
                    <Button>
                      <Link
                        to={process.env.PUBLIC_URL + "files/Endorsements.html"}
                        target="_blank"
                      >
                        Endorsements
                      </Link>
                    </Button>
                  }
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <CarouselComponent style={{ height: "100%", position: "" }} />
      </Fragment>
      {/* Modal Accouting More Info  */}
      <Dialog
        open={openAccountingDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAccountingDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Accounting"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex" }}
          >
            <div style={{ padding: "10px" }}>
              Rocio Briones
              <br />
              Tel (281) 214-3700 / Ext 3710
              <br />
              <a href="mailto:rbriones@gsiay.com">rbriones@gsiay.com</a>
            </div>
            <div style={{ padding: "10px" }}>
              Gagandeep Singh
              <br />
              Tel (281) 214-3700 / Ext 3724
              <br />
              <a href="mailto:gtsingh@gsiay.com">gtsingh@gsiay.com</a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAccountingDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default NavBar1;
