import { Card, CardContent, Grid, makeStyles, Typography, CardActionArea} from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import trailblazersLogo from "../Img/trailblazer.png";
import whaleLogo from "../Img/CWIS-Whale.png";
import AUW from "../Img/AUW.png";
import btis from "../Img/btis.png";
import Coverwhale from "../Img/Coverwhale.png"
import adriatic from "../Img/adriatic.png"

const useStyles = makeStyles({
    root: {
        minWidth: 250,
        maxWidth: 250,
        margin: 20,
        height: '100%',
    },
    textSecondary: {
        fontSize: 12
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15
    },
    // contactBackground: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '50% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right',
    // },
    // contactBackgroundMobil: {
    //     backgroundImage: `url(${background}) !important`,
    //     backgroundSize: '100% 100%',
    //     backgroundColor: 'white',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'right'
    // }
    logos: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 150
        
    },
});

const Markets = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="markets">
                <br />
                <h2>Markets</h2>
                <FadeInSection>
                    <Grid container >
                        <Grid item xs={12} md={3} lg={3}>
                            <Card className={classes.root} >
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.logos}>
                                            <img alt="trailblazers logo" src={trailblazersLogo}></img>
                                        </div>
                                            {/* TRAILBLAZERS */}
                                        {/* <Typography className={classes.textSecondary} variant="body2" color="textSecondary" component="p">
                                            TRAILBLAZERS INSURANCE COMPANY, INC. RRG specializes in Commercial Auto Liability, with superior service, Products, and quick response.
                                        </Typography> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Card className={classes.root} >
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.logos}>
                                            <img alt="trailblazers logo" src={AUW}></img>
                                        </div>
                                            {/* TRAILBLAZERS */}
                                        {/* <Typography className={classes.textSecondary} variant="body2" color="textSecondary" component="p">
                                            TRAILBLAZERS INSURANCE COMPANY, INC. RRG specializes in Commercial Auto Liability, with superior service, Products, and quick response.
                                        </Typography> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Card className={classes.root} >
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.logos}>
                                            <img alt="trailblazers logo" src={btis}></img>
                                        </div>
                                            {/* TRAILBLAZERS */}
                                        {/* <Typography className={classes.textSecondary} variant="body2" color="textSecondary" component="p">
                                            TRAILBLAZERS INSURANCE COMPANY, INC. RRG specializes in Commercial Auto Liability, with superior service, Products, and quick response.
                                        </Typography> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Card className={classes.root} >
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.logos}>
                                            <img alt="trailblazers logo" src={Coverwhale}></img>
                                        </div>
                                            {/* TRAILBLAZERS */}
                                        {/* <Typography className={classes.textSecondary} variant="body2" color="textSecondary" component="p">
                                            TRAILBLAZERS INSURANCE COMPANY, INC. RRG specializes in Commercial Auto Liability, with superior service, Products, and quick response.
                                        </Typography> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>                       
                    </Grid>
                    <br />
                    <Grid container >
                        <Grid item xs={12} md={3} lg={3}>
                            <Card className={classes.root} >
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.logos}>
                                            <img alt="trailblazers logo" src={adriatic}></img>
                                        </div>
                                            {/* TRAILBLAZERS */}
                                        {/* <Typography className={classes.textSecondary} variant="body2" color="textSecondary" component="p">
                                            TRAILBLAZERS INSURANCE COMPANY, INC. RRG specializes in Commercial Auto Liability, with superior service, Products, and quick response.
                                        </Typography> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </FadeInSection>
            </section>
        </Fragment>
    );
}

export default Markets;