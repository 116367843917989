import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../Style/style2.css';
import trailer3 from "../../Img/1.jpg";
import trailer4 from "../../Img/2.jpg";
import trailer5 from "../../Img/4.jpg";
import trailer6 from "../../Img/6.jpg";
import trailer8 from "../../Img/8.jpg";
import { Fragment, useState } from 'react';


const CarouselComponent = () => {
    const [value, setValue] = useState(0);

    const onChange = value => {
        console.log(value)
        setValue(value);
    }
    return (
        <Fragment>
            <Carousel
                style={{ height: '110%', backgroundColor: 'black' }}
                onChange={onChange}
                showStatus={false}
                stopOnHover={false}
                autoPlay
                showThumbs={false}
                infiniteLoop
                interval={6000}
                transitionTime={1000}
            >
            
                <div style={{ height: '100%' }}>
                    <img style={{ height: '100%', objectFit: 'cover'}} src={trailer3} />
                </div>
                <div style={{ height: '100%' }}>
                    <img style={{ height: '100%', objectFit: 'cover' }} src={trailer4} />
                </div>
                <div style={{ height: '100%' }}>
                    <img style={{ height: '100%', objectFit: 'cover' }} src={trailer5} />
                </div>
                <div style={{ height: '100%' }}>
                    <img style={{ height: '100%', objectFit: 'cover' }} src={trailer6} />
                </div>
                <div style={{ height: '100%' }}>
                    <img style={{ height: '100%', objectFit: 'cover' }} src={trailer8} />
                </div>
            </Carousel>
        </Fragment>
    )
};

export default CarouselComponent;