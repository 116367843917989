import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  DialogContentText,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { forwardRef, Fragment, React, useState } from "react";
import logo from "../Img/roundGsiayLogo.png";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PhoneIcon from "@material-ui/icons/Phone";
import CarouselComponent from "./Helpers/CarouselComponent";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CallIcon from "@material-ui/icons/Call";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import clsx from "clsx";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import { Link } from "react-router-dom";
import Banner from "../Img/epay2.png";

const useStyles = makeStyles((theme) => ({
  nested: {
    padding: "10px 10px 10px 80px !important",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  buttonQuote: {
    backgroundColor: "#f78320",
    fontWeight: "bold",
    color: "white !important",
    width: "185px !important",
    marginRight: "15px",
  },
  buttonsBap: {
    backgroundColor: "#f78320",
    fontWeight: "bold",
    marginRight: "15px",
    color: "white !important",
    width: "185px",
  },
  buttonContact: {
    backgroundColor: "#f78320",
    fontWeight: "bold",
    color: "white !important",
    width: "185px !important",
  },
}));
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NavBar = () => {
  const classes = useStyles();
  //   const width = useMediaQuery("(min-width:700px)");
  const [open, setOpen] = useState(false);
  const [openAccountingDialog, setOpenAccountingDialog] = useState(false);
  const [currentSection, setCurrentSection] = useState("#coverages");

  const handleClick = () => {
    setOpen(!open);
  };

  const handleCloseCoverages = () => {
    setOpen(false);
  };

  const cardClick = (e) => {
    setOpenAccountingDialog(true);
  };

  const handleCloseAccountingDialog = () => {
    setOpenAccountingDialog(false);
  };

  const handleNextSectionClick = () => {
    let section = window.location.href;
    section = section.substring(section.indexOf("#"));
    console.log(section);
    switch (section) {
      case "":
      case "#home":
        setCurrentSection("#markets");
        break;
      case "#coverages":
        setCurrentSection("#about");
        break;
      case "#markets":
        setCurrentSection("#getquote");
        break;
      case "#about":
        setCurrentSection("#becomeproducer");
        break;
      case "#getquote":
        setCurrentSection("#contact");
        break;
      case "#becomeproducer":
        setCurrentSection("#claims");
        break;
      case "#contact":
        setCurrentSection("#officeofcompliance");
        break;
      case "#claims":
        setCurrentSection("#careers");
        break;
      case "#officeofcompliance":
        setCurrentSection("#resources");
        break;
      case "#careers":
        setCurrentSection("#home");
        break;
      case "#resources":
        setCurrentSection("#coverages");
        break;
      case "#lossruns":
        setCurrentSection("#lossruns");
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <header id="home"></header>
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>
        <Grid container>
          <Grid
            item
            lg={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <ul id="nav" className="nav">
              <li className="current">
                <a
                  onMouseEnter={handleCloseCoverages}
                  className="smoothscroll"
                  href="#home"
                >
                  HOME
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#coverages">
                  PRODUCT LINES{" "}
                </a>
              </li>
              <li>
                <a
                  className="smoothscroll"
                  href="#markets"
                  onMouseEnter={handleCloseCoverages}
                >
                  MARKETS
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#about">
                  ABOUT US
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#getquote">
                  GET QUOTE
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#becomeproducer">
                  BECOME PRODUCER
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#contact">
                  CONTACT US
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#claims">
                  CLAIMS
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#officeofcompliance">
                  COMPLIANCE
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#careers">
                  CAREERS
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#preferedvendors">
                  VENDORS
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#resources">
                  RESOURCES
                </a>
              </li>
              <li>
                <a className="smoothscroll" href="#lossruns">
                  LOSS RUNS
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Collapse
          in={open}
          //   timeout="auto"
          //   unmountOnExit
          //   style={{ backgroundColor: "#132d5a", color: "white" }}
        >
          <List component="div" disablePadding onMouseLeave={handleClick}>
            <ListItem className={classes.nested}>
              Commercial Auto liability
            </ListItem>
            <ListItem className={classes.nested}>
              Collision coverage / Physical Damage
            </ListItem>
            <ListItem className={classes.nested}>
              Uninsured and underinsured motorist coverage
            </ListItem>
            <ListItem className={classes.nested}>
              Comprehensive / Physical Damage
            </ListItem>
            <ListItem className={classes.nested}>
              Motor Truck Cargo (MTC)
            </ListItem>
            <ListItem className={classes.nested}>Trailer Interchange</ListItem>
          </List>
        </Collapse>
      </nav>
      <Fragment>
        <Grid
          container
          style={{
            height: "70vh",
            position: "absolute",
            bottom: "0px",
            backgroundColor: "#0a2a44",
          }}
        >
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "white", height: "65px" }}
          >
            <Grid
              container
              alignItems="center"
              alignContent="center"
              style={{ flexFlow: "column" }}
            >
              <Grid item xs={12} style={{ display: "flex" }}>
                <Card
                  className={clsx("menuCardsHover")}
                  id="acc"
                  onClick={cardClick}
                  elevation={0}
                  style={{ width: "200px" }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: "#36af20" }}
                      >
                        <AccountBalanceIcon style={{ color: "white" }} />
                      </Avatar>
                    }
                    title="Accounting"
                    subheader="More info"
                  />
                </Card>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  style={{ margin: "10px" }}
                />
                <Card
                  className={clsx("menuCardsHover")}
                  id="cs"
                  elevation={0}
                  onClick={() => (window.location.href = "#contact")}
                  style={{ width: "210px" }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: "#36af20" }}
                      >
                        <CallIcon style={{ color: "white" }} />
                      </Avatar>
                    }
                    title="Customer Service"
                    subheader="More info"
                  />
                </Card>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  style={{ margin: "10px" }}
                />
                <Card
                  className={clsx("menuCardsHover")}
                  id="cert"
                  elevation={0}
                  style={{ width: "200px" }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: "#36af20" }}
                      >
                        <InsertDriveFileIcon style={{ color: "white" }} />
                      </Avatar>
                    }
                    title={
                      <Button>
                        <Link
                          to={
                            process.env.PUBLIC_URL + "files/Certificates.html"
                          }
                          target="_blank"
                        >
                          Certificates
                        </Link>
                      </Button>
                    }
                  ></CardHeader>
                </Card>
                <Card
                  className={clsx("menuCardsHover")}
                  id="cert"
                  elevation={0}
                  style={{ width: "220px" }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ backgroundColor: "#36af20" }}
                      >
                        <InsertDriveFileIcon style={{ color: "white" }} />
                      </Avatar>
                    }
                    title={
                      <Button>
                        <Link
                          to={
                            process.env.PUBLIC_URL + "files/Endorsements.html"
                          }
                          target="_blank"
                        >
                          Endorsements
                        </Link>
                      </Button>
                    }
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
           <CarouselComponent style={{ height: "100%",position:"fixed" }} />
        </Grid>      
      </Fragment>
      {/* Modal Accouting More Info  */}
      <Dialog
        open={openAccountingDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAccountingDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Accounting"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ display: "flex" }}
          >
            <div style={{ padding: "10px" }}>
              Rocio Briones
              <br />
              Tel (281) 214-3700 / Ext 3710
              <br />
              <a href="mailto:rbriones@gsiay.com">rbriones@gsiay.com</a>
            </div>
            <div style={{ padding: "10px" }}>
              Gagandeep Singh
              <br />
              Tel (281) 214-3700 / Ext 3724
              <br />
              <a href="mailto:gtsingh@gsiay.com">gtsingh@gsiay.com</a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAccountingDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default NavBar;
